import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Negation`}</strong>{` in any language is very common and so in Arabic language. There are multiple ways to negate a sentence in Arabic language and one among them is to use ما at the beginning of sentence.`}<br parentName="p"></br>{`
`}{`Again Arabic being a colorful language comes with a colorful set of rules when we use ما to negate a sentence. So without further Ado lets begin and learn its rules (إن شاء الله)`}</p>
    <h2>{`Types of Negation with ما`}</h2>
    <p>{` `}<strong parentName="p" {...{
        "className": "arabic"
      }}>{`أَقْسام ما النافية`}</strong></p>
    <p>{`There are two types of ما النافية in Arabic Grammar  `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.22085889570552%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4UlEQVQY042OzU/CQBDF+///KcQENXCo1eWjpdqWFpMFxZoUsV4MFwO4O7PdDwdONXBgDnOY935vnudaY4/7F21W/gzy1ahYJ6+beS1BWXduvFNYNi5bbjr9iKXv4WzNV1ul3QWwtcYYANjupAAtoNkL3O0lItKd1POwbrQUEkEBdcTGtXyUp1CBwIMqpNbHGsYZbb2EzfjjMmFF5KdPD3kRzr/K7391jKt4XYSLfMzJQ86Jnz5PXt6mH16v4wc3wzjI/C5jvSgOptWibsP0oeKfpPav7gi+vx0F1wOK4HH5B5wETDcmdRJAAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of ما النافية",
          "title": "Types of ما النافية",
          "src": "/static/b2f1770e6afb33dd084eb9c376044485/c7dcc/negation-with-ma.png",
          "srcSet": ["/static/b2f1770e6afb33dd084eb9c376044485/222b7/negation-with-ma.png 163w", "/static/b2f1770e6afb33dd084eb9c376044485/ff46a/negation-with-ma.png 325w", "/static/b2f1770e6afb33dd084eb9c376044485/c7dcc/negation-with-ma.png 641w"],
          "sizes": "(max-width: 641px) 100vw, 641px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`ما النافية الحجازية`}</h3>
    <p>{`Characteristics of ما النافية الحجازية are as follows`}</p>
    <ul>
      <li parentName="ul">{`This ما is used by tribes of Hijaz e.g. Makkah`}</li>
      <li parentName="ul">{`This ما is like ليس in the meaning and also in the function in the sentence.`}</li>
      <li parentName="ul">{`This ما affects the sentence after it i.e. it تعمل `}</li>
      <li parentName="ul">{`Example: ما زيدٌ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مجتهداً`}</strong>{`  `}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of ما زيدٌ مجتهداً`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ما`}</strong>{`
ما الحجازية حرف نفي مبني لا محل له من الإعراب  `}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`زيد`}</strong>{`
اسم ما الحجازية مرفوع`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهداً`}</strong>{`
خبر ما الحجازية منصوب`}</p>
    </blockquote>
    <h3>{`ما النافية التميمية`}</h3>
    <p>{`Characteristics of ما النافية التميمية are as follows`}</p>
    <ul>
      <li parentName="ul">{`This ما is used by tribes of Bani Tamim`}</li>
      <li parentName="ul">{`This ما doesn't affect the sentence after it i.e. لا تعمل`}</li>
      <li parentName="ul">{`Example: ما زيدٌ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مجتهدٌ`}</strong></li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab of ما زيدٌ مجتهدٌ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ما`}</strong>{`
ما التميمية حرف نفي مبني لا محل له من الإعراب  `}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`زيد`}</strong>{`
مبتدأ مرفوع`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`مجتهداً`}</strong>{`
خبر مرفوع`}</p>
    </blockquote>
    <br />
    <p>{`Quran came down in language of Hijaz i.e. in the language of people of Makkah. So we often find negation with ما النافية الحجازية  `}</p>
    <br />
    <p><strong parentName="p">{`Quran Examples:`}</strong></p>
    <ul>
      <li parentName="ul">{`ما هذا بشر`}<strong parentName="li">{`اً`}</strong>{` (Ref: 12:31)`}
        <ul parentName="li">
          <li parentName="ul">
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`ما`}</strong>{`
ما الحجازية حرف نفي مبني لا محل له من الإعراب`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`ما هن أمها`}<strong parentName="li">{`تِ`}</strong>{`هم (Ref: 3:144)`}</li>
    </ul>
    <br />
    <p>{`One fine point to remember is When we use إلا with ما it doesn't affect the sentence.`}<br parentName="p"></br>{`
`}{`Example و`}<strong parentName="p">{`ما`}</strong>{` محمدٌ `}<strong parentName="p">{`إلا`}</strong>{` رسولٌ (Ref: 59:2)`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      